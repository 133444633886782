import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import {
	ActivityIndicator,
	Linking,
	StyleSheet,
	View,
} from "react-native";
import { CustomContext } from "../contexts/custom-context";
import api from "../services/api";
import apiSDK from "../services/apiSDK";
import { connectSocket } from "../services/socket";
import { account } from "../utils/account";

export const CustomProvider: React.FC = ({ children }: any) => {
	const [loading, setLoading] = useState(true);
	const [store, setStore] = useState("");
	const notificationToken = '';

	useEffect(() => {
		getStore();
		loginHidden();
		setTimeout(() => setLoading(false), 2000);
	}, []);

	const getStore = async () => {
		const urlbase = (await Linking.getInitialURL()) || "";
		const url = new URL(urlbase);
		const storeId = url.searchParams.get("store") as string;
		if (!storeId) return
		setStore(storeId);
	};

	const loginHidden = () => {
		apiSDK.session.login(account, notificationToken).then((response) => {
			Cookies.set("authorization", response.data.token.token);
			const authToken = `Bearer ${response.data.token.token}`;
			api.defaults.headers.common["Authorization"] = authToken;
		});
	};

	const styles = StyleSheet.create({
		container: {
			flex: 1,
			justifyContent: "center",
		},
	});


	useEffect(() => {
		connectSocket();
	}, [])

	return (
		<CustomContext.Provider
			value={{
				storeId: store,
				currentLatitude: "0",
				currentLongitude: "0",
				setStore: setStore
			}}
		>
			{loading ? (
				<View style={styles.container}>
					<ActivityIndicator
						size="large"
						color="#30374A"
					/>
				</View>
			) : (
				children
			)}
		</CustomContext.Provider>
	);
};
