import { Payment } from '@inhouse-market/sdk';
import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';

import Cookies from 'js-cookie';
import api from '../../services/apiSDK';
import { account } from '../../utils/account';
import { queryKeys } from '../queryClient';

async function refreshToken() {
  try {
    const notificationToken = ""
    const response = await api.session.login(account, notificationToken);
    const newToken = `Bearer ${response.data.token.token}`;
    Cookies.set("authorization", response.data.token.token);
    return newToken;
  } catch (error) {
    return null;
  }
}

export default function usePurchase() {
  const mutationFn = async (purchaseOption: Payment.CreatePurchase) => {
    let attempts = 0;

    while (attempts < 3) {
      try {
        const data = await api.customer.payment.purchase(purchaseOption);
        return data;
      } catch (error: any) {
        const status = error?.data?.response?.status;

        Sentry.captureException(error, {
          extra: {
            purchaseOption: JSON.stringify(purchaseOption),
            response: error?.data?.response ? JSON.stringify(error?.data?.response?.data) : null,
            status,
            headers: error?.data?.response ? JSON.stringify(error?.data?.response?.headers) : null,
            message: error?.data?.response?.data?.message,
            stack: error?.stack,
            attempts,
          },
        });

        if (status === 401 && attempts < 2) {
          const newToken = await refreshToken();
          if (!newToken) throw error;
          attempts++;
          continue;
        }

        throw error;
      }
    }
  };

  return useMutation({
    mutationFn,
    mutationKey: queryKeys.PURCHASE,
  });
}
