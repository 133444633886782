import { ShoppbudAPI } from '@inhouse-market/sdk';

import { endpoints } from "../env";

import pkg from "../../package.json";

import Cookies from 'js-cookie';

export * from '@inhouse-market/sdk';

const apiSDK = new ShoppbudAPI({
  async getTokenFn() {
    const authToken = Cookies.get('authorization');
    return authToken!;
  },
  platformAndVersion: "web",
  appVersion: pkg?.version || '',
  url: endpoints.api,
});

export default apiSDK;
