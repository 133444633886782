import { NavigationProp, RouteProp, useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { Header } from "../../components/Header";
import {
	ConfirmButton,
	ContainerContentErro,
	ContentButtonProducts,
	ContentErro,
	ErroIcon,
	ErroText,
	TextConfirmButton,
} from "./styles";

const arrayMessageErros = [
	"A transação não foi autorizada pelo emissor do seu cartão. Por favor, entre em contato com o emissor ou utilize outra forma de pagamento."
]

type Navigation = NavigationProp<any>;

interface ErrorMessage {
	message?: string
}

export interface ApiError {
	data: {
		response: {
			data: {
				errors: ErrorMessage[];
				message?: string;
			};
		};
	}
}

interface RouteParams {
	key: string;
	name: string;
	path: any;
	error: ApiError
}

type ProductRouteProp = RouteProp<Record<string, RouteParams>, 'Erro'>;

const Erro: React.FC<{ route?: ProductRouteProp }> = ({ route }) => {
	const navigation = useNavigation<Navigation>();
	const [errorPayment, setErrorPayment] = useState(route?.params?.error)
	const [errorPaymentModal, setErrorPaymentModal] = useState<{
		title: string;
		description: string;
	}>({
		title: 'Falha na operação',
		description: "Desculpe-nos, algo deu errado ao processar seu pagamento. Tente novamente mais tarde."
	});

	useEffect(() => {
		if (route?.params?.error) {
			setErrorPayment(route.params.error);
		}
	}, [route])

	useEffect(() => {
		if (errorPayment) {
			const errorMessage = errorPayment?.data?.response?.data?.message;
			const code = (errorPayment?.data?.response?.data?.message as any)?.code === "BAD_REQUEST"

			if (typeof errorMessage === 'string') {
				if (errorMessage.includes('FRAUD_PREVENTION')) {
					setErrorPaymentModal({
						title: "Atenção",
						description: "Sua conta foi bloqueada por motivos de segurança. Favor entrar em contato com o suporte"
					});
				}
				if (arrayMessageErros.includes(errorMessage)) {
					setErrorPaymentModal({
						title: "Erro!",
						description: errorMessage
					})
				}
			}
			if (!code && errorMessage?.endsWith("estoque maior que registrado na loja.")) {
				setErrorPaymentModal({
					title: "Erro!",
					description: errorMessage
				})
			}
			if (!code && errorMessage?.endsWith("a loja não permite essa operação.")) {
				setErrorPaymentModal({
					title: "Erro!",
					description: errorMessage
				})
			}
			if (!code && errorMessage?.endsWith("bloqueado para a venda")) {
				setErrorPaymentModal({
					title: "Erro!",
					description: errorMessage
				})
			}
		}
	}, [errorPayment]);


	const handleNavigateTo = () => {
		navigation.navigate("Loja");
	};

	const emptyIcon = require("../../../assets/error.png");

	return (
		<>
			<Header />
			<ContainerContentErro>
				<ContentErro>
					<ErroIcon source={emptyIcon} />
					<ErroText>
						{errorPaymentModal?.description}
					</ErroText>
				</ContentErro>
				<ContentButtonProducts>
					<ConfirmButton onPress={handleNavigateTo}>
						<TextConfirmButton>VER PRODUTOS</TextConfirmButton>
					</ConfirmButton>
				</ContentButtonProducts>
			</ContainerContentErro>
		</>
	);
};

export default Erro;
